import { Node } from 'djedi-react';
import { useRouter } from 'next/router';

import HamburgerButtonSVG from 'assets/icons/hamburger-button.svg';
import DarkLogo from 'assets/images/logo-black-text.svg';
import Clickable from 'components/Clickable';
import withOrgId from 'hocs/withOrgId';
import useOrgMembership from 'hooks/useOrgMembership';
import useOrgSurveyResults from 'hooks/useOrgSurveyResults';
import usePlan from 'hooks/usePlan';
import useUser from 'hooks/useUser';
import cx from 'utils/cx';
import hasPrivilege from 'utils/hasPrivilege';
import DropdownLink from '../DropdownLink';
import classes from '../Header/Header.module.scss';
import ProfileInfo from '../ProfileInfo';

export type LinkProps = {
  menuOpen: boolean;
  toggleMenu: () => void;
  orgId: number;
};

const NavigationBar: React.FC<LinkProps> = ({ menuOpen, toggleMenu, orgId }) => {
  const { user, isLoading: userIsLoading } = useUser();
  const { orgMembership, membership } = useOrgMembership();
  const { plan } = usePlan(orgId);
  const router = useRouter();
  const orgSurvey = orgMembership?.org_surveys.find(({ is_primary }) => is_primary);
  const { orgSurveyResults } = useOrgSurveyResults(orgSurvey?.id);

  const hasPlan = plan != null;
  const hasOrgSurveyResults = orgSurvey != null && orgSurveyResults != null;

  if (userIsLoading || user == null) {
    return null;
  }

  const Divider = () => <li className={cx(classes['item'], classes['divider'])} tabIndex={-1} />;

  const KnowledgeBaseLinks = () => (
    <DropdownLink
      links={[
        {
          children: <Node uri="header/dropdown/links/base">Allt material</Node>,
          href: '/knowledge-base/',
        },
        {
          children: <Node uri="header/dropdown/links/interviews">Intervjuer</Node>,
          href: '/knowledge-base/interviews',
        },
        {
          children: <Node uri="header/dropdown/links/tutorials-guides">Övningar & Guider</Node>,
          href: '/knowledge-base/tutorials-guides',
        },
        {
          children: <Node uri="header/dropdown/links/cases">Företagsexempel</Node>,
          href: '/knowledge-base/cases',
        },
        {
          children: <Node uri="header/dropdown/links/explanations">Explanations</Node>,
          href: '/knowledge-base/explanations',
        },
        {
          children: <Node uri="header/dropdown/links/waves">Waves of change</Node>,
          href: '/knowledge-base/waves',
        },
        {
          children: <Node uri="header/dropdown/links/blog">Blogg</Node>,
          href: '/knowledge-base/blog',
        },
      ]}
    >
      <Node uri="header/dropdown/title">Kunskapsbas</Node>
    </DropdownLink>
  );

  return (
    <header className={cx(classes['container'], menuOpen && classes['open'])}>
      <div className={classes['inner']}>
        <Clickable className={classes['logo']} variant="inline" href="/">
          <DarkLogo />
        </Clickable>
        <button className={cx(classes['toggle'])} onClick={toggleMenu}>
          <HamburgerButtonSVG />
        </button>
        <nav className={classes['navigation']}>
          <ul className={classes['links']}>
            {hasPrivilege(membership?.role, 'program_manager') && (
              <DropdownLink href="/identify" active={router.asPath.startsWith('/identify')}>
                <Node uri="header/identity">Nuläge</Node>
              </DropdownLink>
            )}
            {hasPrivilege(membership?.role, 'program_manager') && (
              <DropdownLink
                href="/ai"
                active={
                  router.asPath.startsWith('/ai') &&
                  !router.asPath.startsWith('/ai-activity-creator')
                }
              >
                <Node uri="header/ai">Målbild</Node>
              </DropdownLink>
            )}
            {(hasPlan || hasPrivilege(membership?.role, 'program_owner')) && (
              <DropdownLink href="/plan" active={router.asPath.startsWith('/plan')}>
                <Node uri="header/plan">Planera</Node>
              </DropdownLink>
            )}
            {hasPlan && (
              <DropdownLink href="/do" active={router.asPath.startsWith('/do')}>
                <Node uri="header/do">Göra</Node>
              </DropdownLink>
            )}
            {hasPrivilege(membership?.role, 'program_manager') && (
              <DropdownLink
                href="/ai-activity-creator"
                disabled={!hasOrgSurveyResults}
                active={router.asPath.startsWith('/ai-activity-creator')}
                badge="Beta"
              >
                <Node uri="header/ai-activity-creator">Aktiviteter</Node>
              </DropdownLink>
            )}
            <Divider />
            <KnowledgeBaseLinks />
            <Divider />
            <ProfileInfo />
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default withOrgId(NavigationBar);
