import { useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { API_URL, ENDPOINTS } from 'api/consts';
import { GroupSchema, MemberSchema, OrgSurveyResultsSchema } from 'types/Changemkr';
import { fetchOptions, FetchResult, fetchWrapper, successResponse } from '../utils/fetchUtils';

export type OrgSurveyResultsContext = {
  isLoading: boolean;
  orgSurveyResults?: OrgSurveyResultsSchema;
  results: (
    compareTarget1?: GroupSchema | MemberSchema,
    compareTarget2?: GroupSchema | MemberSchema,
  ) => Promise<FetchResult<OrgSurveyResultsSchema>>;
};

export default function useOrgSurveyResults(
  orgSurveyId?: number,
  compareTarget1?: GroupSchema | MemberSchema,
  compareTarget2?: GroupSchema | MemberSchema,
): OrgSurveyResultsContext {
  const compareTarget1Id = useMemo(() => {
    if (!compareTarget1) {
      return undefined;
    }

    return 'id' in compareTarget1 ? compareTarget1.id : compareTarget1.user.id;
  }, [compareTarget1]);

  const compareTarget2Id = useMemo(() => {
    if (!compareTarget2) {
      return undefined;
    }

    return 'id' in compareTarget2 ? compareTarget2.id : compareTarget2.user.id;
  }, [compareTarget2]);

  const queryClient = useQueryClient();
  const { isLoading, data: orgSurveys } = useQuery(
    ['org-survey-results', orgSurveyId, compareTarget1Id, compareTarget2Id],
    async () => {
      const responseData = await fetchWrapper(
        API_URL + ENDPOINTS['org-survey']['results'](orgSurveyId!, compareTarget1, compareTarget2),
        fetchOptions('GET'),
      );

      return responseData as OrgSurveyResultsSchema;
    },
    {
      enabled: orgSurveyId != null,
    },
  );

  const setOrgSurveyResults = (data: OrgSurveyResultsSchema) => {
    queryClient.setQueryData(['org-survey-results', orgSurveyId], structuredClone(data));
  };

  const results = async (
    compareTarget1?: GroupSchema | MemberSchema,
    compareTarget2?: GroupSchema | MemberSchema,
  ) => {
    if (orgSurveyId == null) {
      throw new Error('Cannot fetch results when org survey id is undefined');
    }

    const responseData = await fetchWrapper(
      API_URL + ENDPOINTS['org-survey']['results'](orgSurveyId, compareTarget1, compareTarget2),
      fetchOptions('GET'),
    );

    if (responseData && 'success' in responseData && responseData.success === false) {
      return responseData;
    }

    setOrgSurveyResults(responseData as OrgSurveyResultsSchema);

    return successResponse(responseData);
  };

  return { isLoading, orgSurveyResults: orgSurveys, results };
}
